@import '../../styles/var';

.HeaderLogo {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $whiteColor;
  height: 140px;
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 300px;
    max-height: 90px;
  }
}
