@import '../../styles/var';

.sendPaymentContainer.canceled {
  .sendPaymentStructure {
    z-index: 1;
    .imgContent {
      .iconContent {
        .iconCancel {
          position: absolute;
          bottom: 15px;
          right: 20px;
          width: 20px;
          height: 20px;
        }
        &:after {
          z-index: -1;
          content: '';
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background: $backgroundColor;
          position: absolute;
          bottom: 5px;
          right: 10px;
        }
      }
    }
  }
}

