@import 'var';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
html, body, div, span, applet, object, iframe, main,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, input, select, textarea,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, svg {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 13px;
    color: $darkColor;
    font-family: 'Open Sans', sans-serif;
    outline: none !important;
    outline-color: transparent !important;
}

#root {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: $backgroundColor;
}

a {
    text-decoration: none;
}

:focus {
    outline: none !important;
    outline-color: transparent !important;
    outline-style: none !important;
    outline-width: 0 !important;
}
