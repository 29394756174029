@import '../../styles/var';

.stepContainer {
  background: $greyColor;
  overflow: hidden;
  width: 100%;
  padding: 25px 0;
  position: relative;
  z-index: 1;
  &:after {
    z-index: -10;
    content: '';
    background: $mediumLightGreyColor;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 32px;
    left: 0;
  }
  .stepStructure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    .stepContent {
      font-weight: 600;
      text-transform: uppercase;
      color: $whiteColor;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      .spanIcon {
        display: block;
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
        background: $greenColor;
        border-radius: 50%;
        border: 5px solid $whiteColor;
      }
      &.active {
        color: $greenColor;
        position: relative;
        .spanIcon {
          background: $whiteColor;
          border: 5px solid $greenColor;
        }
        &:after {
          z-index: -1;
          content: '';
          background: $greenColor;
          width: 100vw;
          height: 6px;
          position: absolute;
          top: 7px;
          right: 50%;
        }
        &:last-child {
          &:after {
            right: inherit;
            width: 200vw;
            left: -100vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .stepContainer {
    &:after {
      width: 6px;
      height: 100%;
      top: 0;
      left: 32px;
    }
    .stepStructure {
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .stepContent {
        padding: 5px 25px;
        flex-direction: row;
        .spanIcon {
          content: '';
          margin-bottom: 0;
          margin-right: 15px;
        }
        &.active {
          color: $greenColor;
          position: relative;
          .spanIcon {
            background: $whiteColor;
            border: 5px solid $greenColor;
          }
          &:after {
            height: 400px;
            width: 6px;
            bottom: 50%;
            left: 32px;
            top: inherit;
            right: inherit;
          }
          &:last-child {
            &:after {
              right: inherit;
              width: 6px;
              left: 32px;
              bottom: -100px;
            }
          }
        }
      }
    }
  }
}

.hektor {
  .stepContainer {
    .stepStructure {
      .stepContent {
        .spanIcon {
          background: $hektorColor;
        }
        &.active {
          color: $hektorColor;
          .spanIcon {
            background: $whiteColor;
            border: 5px solid $hektorColor;
          }
          &:after {
            background: $hektorColor;
          }
        }
      }
    }
  }
}
