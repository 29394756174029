@import '../../styles/var';

.spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.msg {
    text-align: center;
    text-transform: uppercase;
    color: $greyColor;
    padding-top: 5px;
    margin-top: 15px;
}
