@import '../../styles/var';

.homeContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .homeStructure {
    z-index: 1;
    position: relative;
    text-align: center;
    &:before {
      position: absolute;
      bottom: 0;
      left: 75%;
      content: '';
      z-index: -1;
      width: 30vh;
      height: 30vh;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      border-radius: 25px;
      box-shadow: 0 0 30px -5px $mediumLightGreyColor;
    }
    &:after {
      position: absolute;
      top: 100%;
      right: 100%;
      content: '';
      z-index: -1;
      width: 10vh;
      height: 10vh;
      transform: rotate(30deg);
      -webkit-transform: rotate(30deg);
      -moz-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      -o-transform: rotate(30deg);
      border-radius: 25px;
      box-shadow: 0 0 30px -5px $mediumLightGreyColor;
    }
    .titre {
      text-align: center;
      font-size: 40px;
      text-transform: uppercase;
      color: $greyColor;
      padding-top: 5px;
      margin-top: 15px;
      border-top: 1px solid $greyColor;
    }
    .logo {
      width: 100%;
      max-width: 500px;
    }
  }
}

@media screen and (max-width: 580px) {
  .homeContainer {
    .homeStructure {
      padding: 25px;
      .titre {
        font-size: 20px;
      }
    }
  }
}
