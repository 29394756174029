@import 'var';

/* CLASSE DEFAULT */

$time: 0.25s;
$timeText: 250;
$iteration: 10;

@mixin transition {
  @for $i from 1 through $iteration {
    .transition#{$i * $timeText} {
      transition: ($i * $time);
      -webkit-transition: ($i * $time);
      -moz-transition: ($i * $time);
      -ms-transition: ($i * $time);
      -o-transition: ($i * $time);
    }
  }
}

@include transition;

.blockContainer_default {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

/* RESPONSIVE FRAMEKORK */

.bxrow {
  display: flex;
  flex-wrap: wrap;
}

$columns: 12;

@mixin bx($letter) {
  .bx#{$letter}0 {
    display: none;
  }
  @for $i from 1 through $columns {
    .bx#{$letter}#{$i} {
      display: flex;
      width: percentage($i / $columns);
    }
  }
}

@include bx('d');

@media screen and (max-width: 980px){
  @include bx('t');
}

@media screen and (max-width: 580px){
  @include bx('m');
}

/* FORMULAIRE DEFAULT */

.formContainer {
  padding: 25px 15px;
  .titreForm {
    background: $greyColor;
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 5px;
    .titreContent {
      font-size: 22px;
      font-weight: 600;
      color: $whiteColor;
    }
  }
  .formStructure {
    background: #fff;
    border-radius: 5px;
    padding: 25px 50px;
    box-shadow: 0 0 5px 0 $lightGreyColor;
    .formSectionContainer {
      flex-direction: column;
      justify-content: space-between;
    }
    .formSectionTitre {
      display: flex;
      padding: 15px 10px 10px 10px;
      &:before {
        width: 4px;
        display: block;
        background: $greenColor;
        height: 31px;
        content: '';
      }
      .titreContent {
        padding: 2px 0;
        font-size: 20px;
        padding-left: 25px;
      }
    }
    .formContent {
      display: flex;
      flex-direction: column;
      padding: 0 10px 10px;
      &.labelContent {
        padding: 0 10px;
      }
      .LabelDefault {
        color: $darkColor;
        font-weight: 700;
        padding-bottom: 5px;
      }
      .inputDefault {
        border-radius: 5px;
        color: $mediumGreyColor;
        border: 2px solid $lightGreyColor;
        padding: 7.5px 15px;
      }
      .inputDefaultContent {
        border-radius: 5px;
        color: $mediumGreyColor;
        border: 2px solid $lightGreyColor;
        position: relative;
        .inputContent {
          border: none;
          padding: 7.5px 15px;
          width: 100%;
          background: none;
          max-height: 33px;
        }
        &::placeholder {
          color: $mediumGreyColor;
        }
        &.validForm {
          border: 2px solid $validColor;
        }
        &.invalidForm {
          border: 2px solid $errorColor;
          &:before {
            content: '';
            width: 15px;
            height: 2px;
            position: absolute;
            top: 16px;
            right: 10px;
            background: $errorColor;
            transform: rotate(45deg);
          }
          &:after {
            content: '';
            width: 15px;
            height: 2px;
            position: absolute;
            top: 16px;
            right: 10px;
            background: $errorColor;
            transform: rotate(-45deg);
          }
        }
      }
      .tabDataContent {
        .labelData {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid $mediumGreyColor;
          font-weight: 700;
          .data {
            text-align: right;
            padding-left: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

/* BUTTON */

.buttonDefaultContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  .buttonDefault {
    @extend .buttonDefault;
  }
}

.buttonConfirmationContainer {
  display: flex;
  padding: 20px 0;

  .buttonDefault {
    @extend .buttonDefault;
  }

  .divButton {
    display: flex;
    width: 50%;

    &.start {
      justify-content: flex-start;
    }

    &.end {
      justify-content: end;
    }
  }
}

.buttonDefault {
  min-width: 125px;
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: #fff;
  background: $greenColor;
  cursor: pointer;
  margin: 5px 0 5px 10px;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background: $darkGreenColor;
  }
}

@media screen and (max-width: 580px) {
  .formContainer {
    .formStructure {
      padding: 15px;
      .formSectionTitre {
        .titreContent {
          font-size: 15px;
        }
      }
    }
    .titreForm {
      .titreContent {
        font-size: 15px;
      }
    }
  }
}


/* COLOR HEKTOR */

.hektor {
  .formContainer {
    .formStructure {
      .formSectionTitre {
        &:before {
          background: $hektorColor;
        }
      }
    }
  }
  .buttonDefaultContainer {
    .buttonDefault {
      background: $hektorColor;
      &:hover {
        background: $darkHektorColor;
      }
    }
  }
}

/* SEND PAGE */

.sendPaymentContainer {
  width: 100%;
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;
  .sendPaymentStructure {
    width: 500px;
    height: 500px;
    border-radius: 25px;
    box-shadow: 0 0 30px 0 $mediumLightGreyColor;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: $validColor;
      width: 100%;
      height: 400px;
      z-index: -2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 300px;
      left: -50%;
      width: 200%;
      height: 500px;
      border-radius: 50%;
      background: $backgroundColor;
      z-index: -1;
    }
    .imgContent {
      position: relative;
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconContent {
        width: 150px;
        height: 150px;
        position: relative;
        .icon {
          width: 150px;
        }
      }
    }
    .sendPaymentContent {
      padding: 0 15px;
      height: 200px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .titre {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 15px;
        color: $greyColor;
      }
      .text {
        font-size: 15px;
        color: $greyColor;
      }
    }
  }
  &.success {
    .sendPaymentStructure {
      &:before {
        background: $validColor;
      }
    }
  }
  &.canceled {
    .sendPaymentStructure {
      &:before {
        background: $errorColor;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .sendPaymentContainer {
    justify-content: flex-start;
    min-height: auto;
    padding: 25px;
    .sendPaymentStructure {
      &:after {
        content: '';
        position: absolute;
        top: 300px;
        left: -50%;
        width: 200%;
        height: 300px;
        border-radius: 50%;
        background: $backgroundColor;
        z-index: -1;
      }
      .imgContent {
        .icon {
          width: 125px;
        }
      }
      .sendPaymentContent {
        .titre {
          font-size: 20px;
        }
      }
    }
  }
}

.btnDisabled {
  background: #ccc !important;
  pointer-events: none;
}
