// Custom colors
$darkColor: #030303;
$greyColor: #494B52;
$mediumGreyColor: #808080;
$mediumLightGreyColor: #c7c7c7;
$lightGreyColor: #E1E1E1;
$lighterGreyColor: #F3F3F3;
$whiteColor: #fff;
$greenColor: #BED425;
$darkGreenColor: #9db11f;
$hektorColor: #F59A00;
$darkHektorColor: #C27A00;
$blueColor: #39A4DC;
$redColor: #CC0000;
$validColor: #34cf95;
$errorColor: #fd1d63;
$backgroundColor: #EFEFEF;
$boxShadow: 0px 0px 16px #00000029;

// Declaration of Open Sans Extra-Bold
$extraBold: "Open Sans Extrabold";
@font-face {
    font-family: $extraBold;
    src: url("fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
